.action {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.table-container {
  position: sticky;
  top: 60px; /* Adjust this value based on the actual height of the header */
  flex-grow: 1;
  max-height: calc(100vh - 60px); /* Adjust this value based on the actual height of the header */
  overflow-x: auto; /* Omogućuje horizontalno skrolanje */
  margin: 0 auto; /* Adjusted margin-top to move the table below the header */
  padding: 0 10px; /* Unutarnje margine */
  display: flex; /* Omogućava punu širinu */
  flex-direction: column;
  padding-top: 60px; /* Adjust this value based on the actual height of the header */
  overflow-y: auto; /* Omogućuje vertikalno skrolanje */
}

@media (max-width: 1025px) {
  .table-container {
    padding-top: 100px; /* Adjust this value based on the actual height of the header when it wraps to two lines */
  }
}

.invoice-table {
  width: 100%; /* Pun širina tablice */
  min-width: 600px; /* Minimalna širina tablice za osiguranje skrolanja */
  border-collapse: collapse; /* Eliminira razmake između ćelija */
  margin-top: 0px; /* Razmak iznad tablice */
  color: #ffffff; /* Boja teksta */
  table-layout: fixed; /* Fiksna širina stupaca */
}

.invoice-table th, .invoice-table td {
  padding: 10px;
  border: 1px solid #444;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  
  .invoice-table th {
    background-color: #333333; /* Dark gray for header */
    color: #ffffff; /* White text */
  }
  
  .invoice-table tr:nth-child(even) {
    background-color: #222222; /* Darker gray for even rows */
  }
  
  .invoice-table tr:nth-child(odd) {
    background-color: #333333; /* Slightly lighter gray for odd rows */
  }
  
  .invoice-table tr:hover {
    background-color: #444444; /* Medium gray on hover */
  }
  

  .preview-button {
    background-color: #FFA500; /* Narančasta boja */
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.preview-button:hover {
    background-color: #e69500; /* Tamnija nijansa narančaste na hover */
}

.cancel-button {
  background-color: #d9534f;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
}


.cancel-button:hover {
  background-color: #c9302c;
}

.cancel-button:disabled {
  background-color: #ccc; /* Siva boja za onemogućeni gumb */
  color: #666;            /* Siva boja za tekst */
  cursor: not-allowed;    /* Ikona koja pokazuje da je gumb onemogućen */
}

.paid {
  color: green;
}

.unpaid {
  color: red;
}

@media (max-width: 768px) {
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
  }

  .table-container {
    width: 100vw;
    margin: 0 auto;
    padding: 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 190px; /* Adjust this value based on the actual height of the header */
    overflow-y: auto; /* Omogućuje vertikalno skrolanje */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .invoice-table {
    width: max-content;
    min-width: 800px;
    margin: 20px 0 0 0;
    border-collapse: collapse;
    font-size: 12px; /* Manja veličina fonta za mobilni prikaz */

  }

  .invoice-table th,
  .invoice-table td {
    white-space: nowrap;
    padding: 6px;
    min-width: 100px;
    height: auto;
    vertical-align: middle;
  }

  /* Stil za ćelije koje sadrže gumbe */
  .invoice-table td.action {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Stil za gumbe */
  .invoice-table button {
    margin: 2px;
    padding: 5px 10px;
    height: 30px;
    min-width: 60px;
  }

  .preview-button {
    height: 30px;
    margin: 2px;
  }
}