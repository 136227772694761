body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black; /* Crna pozadina */
    font-family: 'Arial', sans-serif; /* Prilagođena font obitelj */
}

.login-container {
    background-color: rgba(255, 255, 255, 0.1); /* Lagano prozirna pozadina */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 400px; /* Maksimalna širina za desktop prikaz */
}

h2 {
    color: #FFA500; /* Narančasta boja naslova */
    text-align: center;
}

label {
    color: #FFA500; /* Narančasta boja za oznake */
}

input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #FFA500; /* Narančasti obrub za unos */
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2); /* Svijetlo siva pozadina za unos */
    color: white; /* Bijeli tekst u unosu */
}

button {
    width: 100%;
    padding: 10px;
    background-color: #FFA500; /* Narančasta pozadina gumba */
    color: white; /* Bijeli tekst na gumbu */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

button:hover {
    background-color: #FF8C00; /* Tamnija narančasta pri hover */
}

/* Media query za mobilni prikaz */
@media (max-width: 768px) {
    body{
        height: 70vh;
    }
    .login-container {
        padding: 15px;
        box-shadow: none; /* Ukloni shadow za jednostavniji izgled na mobilnom */
    }

    h2 {
        font-size: 1.5em; /* Smanjen font za mobilni */
    }

    input, button {
        font-size: 1em; /* Prilagođena veličina teksta */
    }
}

p {
    color: #FFA500; /* Poruka u narančastoj */
    text-align: center;
}
