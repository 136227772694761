.profile-header {
  background-color: #282c34; /* Boja pozadine headera */
  color: white; /* Boja teksta */
  padding: 10px 15px; /* Smanjeni unutarnji razmak */
  display: flex; /* Flexbox za raspored */
  justify-content: space-between; /* Razmak između lijeve i desne strane */
  align-items: center; /* Poravnanje središnjeg sadržaja */
  position: fixed; /* Fiksna pozicija */
  top: 0; /* Na vrhu stranice */
  left: 0; /* Lijevo */
  right: 0; /* Desno */
  z-index: 1000; /* Osiguraj da je iznad ostalog sadržaja */
  flex-wrap: nowrap; /* Spriječava prelazak u novi red */
}

.header-left {
  display: flex; /* Flexbox za lijevu stranu */
  align-items: center; /* Poravnanje središnjeg sadržaja */
  white-space: nowrap; /* Spriječava prelazak u novi red */
}

.header-left strong {
  margin: 0 5px; /* Razmak oko imena */
}

.header-right {
  display: flex; /* Flexbox za desnu stranu */
  align-items: center; /* Poravnanje središnjeg sadržaja */
}

.header-right a {
  margin-right: 15px; /* Povećan razmak između linkova */
  color: white; /* Boja linkova */
  text-decoration: none; /* Bez podcrtavanja */
  white-space: nowrap; /* Spriječava prelazak u novi red */
}

.header-right a:hover {
  text-decoration: underline; /* Podcrtavanje na hover */
}

.logout-button {
  margin-left: 15px; /* Razmak lijevo od dugmeta */
}
/* Postojeći CSS kod */

@media (max-width: 768px) {
  .profile-header {
    flex-direction: column; /* Postavlja elemente u stupac */
    align-items: flex-start; /* Poravnava elemente na početak */
    padding: 10px; /* Smanjuje padding */
  }

  .header-left {
    width: 100%; /* Širina 100% */
    display: flex; /* Flexbox za lijevu stranu */
    flex-direction: row; /* Postavlja elemente u red */
    align-items: center; /* Poravnava elemente po sredini */
    font-size: 14px; /* Smanjuje veličinu fonta */
    margin-bottom: 10px; /* Razmak ispod */
  }

  .change-user-button {
    margin-left: 5px; /* Razmak lijevo od dugmeta */
    padding: 5px 10px; /* Smanjuje padding dugmeta */
    font-size: 12px; /* Smanjuje veličinu fonta dugmeta */
  }

  .header-right {
    width: 100%; /* Širina 100% */
    display: flex; /* Flexbox za desnu stranu */
    justify-content: space-between; /* Razmak između linkova */
  }

  .header-right a {
    margin: 0 5px; /* Razmak između linkova */
    font-size: 14px; /* Smanjuje veličinu fonta linkova */
  }

  .logout-button {
    margin-left: 5px; /* Razmak lijevo od dugmeta */
    padding: 5px 10px; /* Smanjuje padding dugmeta */
    font-size: 12px; /* Smanjuje veličinu fonta dugmeta */
  }
}