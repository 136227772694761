.profile-header {
  background-color: #282c34; /* Boja pozadine headera */
  color: white; /* Boja teksta */
  padding: 10px 20px; /* Unutarnji razmak */
  display: flex; /* Flexbox za raspored */
  justify-content: space-between; /* Razmak između lijeve i desne strane */
  align-items: center; /* Poravnanje središnjeg sadržaja */
  position: fixed; /* Fiksna pozicija */
  top: 0; /* Na vrhu stranice */
  left: 0; /* Lijevo */
  right: 0; /* Desno */
  z-index: 1000; /* Osiguraj da je iznad ostalog sadržaja */
}

.header-left {
  display: flex; /* Flexbox za lijevu stranu */
  align-items: center; /* Poravnanje središnjeg sadržaja */
  white-space: nowrap; /* Spriječava prelazak u novi red */

}

.header-left strong {
  margin: 0 5px; /* Razmak oko imena */
}

.header-right {
  display: flex; /* Flexbox za desnu stranu */
  align-items: center; /* Poravnanje središnjeg sadržaja */
}

.header-right a {
  margin-right: 15px; /* Razmak između linkova */
  color: white; /* Boja linkova */
  text-decoration: none; /* Bez podcrtavanja */
}

.header-right a:hover {
  text-decoration: underline; /* Podcrtavanje na hover */
}

.logout-button {
  background-color: #f00; /* Crvena boja za dugme Odjava */
  color: white; /* Boja teksta na dugmetu */
  border: none; /* Bez okvira */
  padding: 8px 12px; /* Unutarnji razmak */
  cursor: pointer; /* Ručka za pokazivač */
  border-radius: 4px; /* Zaobljeni rubovi */
}

.logout-button:hover {
  background-color: #c00; /* Tamnija crvena na hover */
}

.services-container {
  display: flex; /* Omogućava lijevu i desnu stranu */
  align-items: center;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dvije kolone */
  gap: 20px; /* Razmak između pravokutnika */
  width: 50%; /* Lijeva polovica */
  position: relative; /* Omogućava pozicioniranje unutar grid-a */
}

.service-box {
  background-color: #f0f0f0; /* Boja pozadine */
  border: 1px solid #ccc; /* Boja okvira */
  border-radius: 5px; /* Zaobljeni kutovi */
  padding: 20px; /* Unutarnji razmak */
  text-align: center; /* Centriraj tekst */
  cursor: pointer; /* Promjena kursora na pointer */
  display: flex; /* Omogućava ravnomjerno raspoređivanje */
  flex-direction: column; /* Rasporedi tekst u stupac */
  justify-content: space-between; /* Rasporedi sadržaj */
}

.service-box:hover {
  background-color: #e0e0e0; /* Promjena boje pozadine na hover */
}


/* Placeholder stil za desnu stranu */
.placeholder {
  background-color: #f0f0f0; /* Pozadina placeholdera */
  padding: 10px; /* Unutarnji razmak */
  margin-left: 20px; /* Razmak lijevo od grid-a */
  border-radius: 5px; /* Zaobljeni rubovi */
  height: fit-content; /* Visina se automatski prilagođava sadržaju */
  display: flex;
  flex-direction: column; /* Održava elemente jedan ispod drugog */
  width: calc(50% - 20px); 
}

.placeholder table {
  margin-top: 10px; /* Razmak između naslova i tablice */
  
}

.placeholder h4 {
  margin-bottom: 10px; /* Razmak ispod naslova */
}

.placeholder ul {
  list-style-type: none; /* Ukloni oznake s popisa */
  padding: 0; /* Ukloni unutarnji razmak */
}

.placeholder li {
  margin: 5px 0; /* Razmak između stavki popisa */
  color: #333; /* Tamnija boja teksta */
  font-size: 16px; /* Promijeni veličinu fonta */
}

/* Opcionalno: Dodaj dodatni stil za hover efekt na stavkama popisa */
.placeholder li:hover {
  background-color: #e0e0e0; /* Promijeni boju pozadine na hover */
  padding: 5px; /* Unutarnji razmak na hover */
  border-radius: 4px; /* Zaobljeni rubovi na hover */
}


.services-table {
  width: 100%; /* Fiksna širina tablice */
  table-layout: fixed; /* Ograničava širinu stupaca */
  border-collapse: collapse;
}

.services-table th, .services-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center; /* Centriranje teksta horizontalno */
  vertical-align: middle; /* Centriranje teksta vertikalno */
  word-wrap: break-word; /* Omogućava prelamanje teksta */
  white-space: normal; /* Osigurava prelamanje teksta */
}

.services-table th {
  background-color: #f0f0f0;
}

.services-table td {
  overflow-wrap: break-word; /* Prelamanje dugačkih riječi */
}


.services-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternirajuće boje redova */
}

.services-table tr:hover {
  background-color: #e0e0e0; /* Promjena boje reda na hover */
}

.remove-icon {
  color: red; /* Crvena boja za ikonu */
  cursor: pointer; /* Kursor mijenja oblik kad se prelazi preko ikone */
  margin-left: 10px; /* Razmak lijevo od ikone */
}

.remove-icon:hover {
  text-decoration: underline; /* Podvučena ikona kad se pređe mišem */
}

.adjust-icon {
  margin: 0 10px; /* Razmak između ikona i količine */
  font-size: 1.5em; /* Povećava veličinu ikona */
  cursor: pointer; /* Promjena kursora na pointer za bolju interakciju */
}

.create-invoice-button {
  margin-top: 10px; /* Razmak od tablice */
  padding: 10px; /* Unutarnji razmak */
  background-color: orange; /* Narančasta pozadina */
  color: white; /* Boja teksta */
  border: none; /* Bez granice */
  border-radius: 5px; /* Zaobljeni rubovi */
  cursor: pointer; /* Promjena pokazivača na ruku */
}

.create-invoice-button:hover {
  background-color: darkorange; /* Tamnija nijansa pri hoveru */
}

.create-invoice-button:disabled {
  background-color: #cccccc; /* Siva boja pozadine */
  color: #666666; /* Siva boja teksta */
  cursor: not-allowed; /* Ikonica koja pokazuje da je gumb onemogućen */
}

.create-invoice-button:disabled:hover {
  background-color: #130256; /* Održava istu sivu boju pri hoveru kad je onemogućen */
  color: #666666; /* Održava istu sivu boju teksta pri hoveru kad je onemogućen */
}

.footer-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Ovaj dio osigurava vertikalno poravnanje */
  margin-top: 10px;
  gap: 10px; /* Razmak između gumba */
}

.add-customer-button, .create-invoice-button  {
  padding: 10px 20px; /* Isti padding za oba gumba */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  line-height: 1; /* Osigurava da se tekst vertikalno centrira unutar gumba */
}

/* Dodaj kupca stilovi */
.add-customer-button {
  background-color: lightgray;
  color: black;
}

.add-customer-button:hover {
  background-color: #706f6f51; /* Siva hover boja */
}

/* Kreiraj račun stilovi */
.create-invoice-button {
  background-color: orange;
  color: white;
}

.create-invoice-button:hover {
  background-color: #ab7208; /* Tamnija narančasta hover boja */
}

.create-quotation-button {
  background-color: #007bff; /* Plava pozadina (možeš promijeniti boju) */
  color: white; /* Boja teksta */
  border: none; /* Bez granice */
  cursor: pointer; /* Ručka za pokazivač */
  font-size: 15px; /* Veličina fonta */
  text-align: center; /* Poravnanje teksta */
  font-weight: bold; /* Podebljani tekst */
  transition: background-color 0.3s; /* Glatka promjena boje pozadine */
}

.create-quotation-button:hover {
  background-color: #0056b3; /* Tamnija nijansa pri hoveru */
}

.create-quotation-button:disabled {
  background-color: #cccccc; /* Siva boja pozadine kad je onemogućen */
  color: #666666; /* Siva boja teksta */
  cursor: not-allowed; /* Ikonica koja pokazuje da je gumb onemogućen */
  font-weight: bold; /* Podebljani tekst čak i kada je onemogućen */
}

.create-quotation-button:disabled:hover {
  background-color: #130256; /* Održava istu sivu boju pri hoveru kad je onemogućen */
  color: #666666; /* Održava istu sivu boju teksta pri hoveru kad je onemogućen */
}


/* Osiguravanje da oba gumba imaju istu širinu */
.add-customer-button, .create-invoice-button {
  min-width: 140px;
  text-align: center;
  margin: 0; /* Uklanjanje margina ako ih ima */
}

.button-container {
  width: 100%;
  max-width: 200px; /* Ensure all buttons have the same maximum width */
  text-align: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Širina modalnog prozora */
  max-width: 900px; /* Maksimalna širina */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px;
  overflow-y: auto; /* Omogućava pomicanje ako je sadržaj predugačak */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
}

.table tr {
  border-bottom: 2px solid orange; /* Narančasta linija između klijenata */
}

.table tr:last-child {
  border-bottom: none; /* Ukloni donju liniju za posljednji red */
}

.close-modal-button {
  margin-top: 20px;
  background-color: red; /* Npr. crvena boja za gumb zatvaranja */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.tab-buttons {
  display: flex; /* Postavi tabove u red */
  justify-content: space-around; /* Rasporedi tabove ravnomjerno */
  margin-bottom: 20px;
}

.tab-buttons button {
  padding: 10px 20px; /* Unutarnje margine */
  border-radius: 5px 5px 0 0; /* Zaobljeni rubovi na vrhu */
  cursor: pointer;
  flex: 1; /* Tabovi će zauzeti jednak prostor */
  text-align: center; /* Poravnanje teksta u sredini */
  transition: background-color 0.3s, color 0.3s; /* Glatka promjena boje */
}

.active-tab {
  background-color: orange; /* Narančasta pozadina za aktivni tab */
  color: white; /* Bijeli tekst za aktivni tab */
  border: 2px solid orange; /* Narančasta ivica za aktivni tab */
}

.active-tab:hover
{
  background-color: rgba(200, 200, 200, 0.7); /* Lagana promjena boje pri hoveru */
  border: 2px solid transparent; /* Prozirna ivica za neaktivne tabove */
}

.inactive-tab {
  background-color: #ccc; /* Siva pozadina za neaktivne tabove */
  color: black; /* Crni tekst za neaktivne tabove */
  border: 2px solid transparent; /* Prozirna ivica za neaktivne tabove */
}

.inactive-tab:hover {
  background-color: rgba(200, 200, 200, 0.7); /* Lagana promjena boje pri hoveru */
  border: 2px solid transparent; /* Prozirna ivica za neaktivne tabove */
}


/* Dodaj stil za tablicu postojećih kupaca */
.clients-table {
  width: 100%;
  border-collapse: collapse; /* Uklanja razmake između ćelija */
  margin-top: 20px;
}

.clients-table th, .clients-table td {
  padding: 10px;
  border: 1px solid #ddd; /* Ograničenje između ćelija */
  text-align: center;
}

.clients-table th {
  background-color: #f0f0f0;
}

.clients-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternativne boje za parne redove */
}

.clients-table tr:hover {
  background-color: #e0e0e0; /* Promjena boje reda pri hoveru */
}

.client-form {
  width: 50%; /* Skraćuje širinu forme */
  margin: 0 auto; /* Centrira formu */
  background-color: #f9f9f9; /* Svijetla pozadina */
  padding: 10px; /* Unutarnji razmak */
  border-radius: 8px; /* Zaobljeni rubovi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Lagana sjena */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centriranje svih elemenata unutar forme */
  width: 100%;
}

.select-customer-button
{
  background-color: orange;
  
}

.select-customer-button:hover
{
  background-color: rgb(224, 147, 4);
}

.form-container {
  width: 50%; /* Smanjuje širinu forme */
  margin: 0 auto; /* Centrira formu */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.form-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Poravnaj elemente s lijeve strane */
  width: 80%; /* Osiguraj da grupa zauzima cijelu širinu */
}

.form-group {
  width: 80%; /* Ograniči širinu input polja i form grupe */
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Poravnaj elemente s lijeve strane */
}

.form-group label {
  display: flex;
  align-items: center; /* Vertikalno poravnanje */
  margin-bottom: 5px;
  color: #d35400; /* Tamno narančasta boja za label */
  width: 100%; /* Osiguraj da label zauzima cijelu širinu */
}

.form-group input[type="radio"] {
  margin-right: 10px; /* Razmak između radio gumba i labela */
}

.form-group input[type="checkbox"] {
  margin-right: 10px; /* Razmak između checkboxa i labela */
}

.checkbox-group {
  display: flex;
  justify-content: center; /* Centriraj checkbox i label */
  align-items: center; /* Vertikalno poravnanje */
  width: 100%; /* Osiguraj da grupa zauzima cijelu širinu */
  margin-bottom: 1.5rem; /* Dodaj razmak ispod checkbox grupe */
}

.form-group .radio-container {
  display: flex;
  flex-direction: row-reverse; /* Postavi radio gumbe jedan ispod drugoga */
  align-items: flex-start; /* Poravnaj radio gumbe s lijeve strane */
  margin-top: 5px; /* Razmak između labela i radio gumba */
}

.form-label {
  margin-bottom: 5px;
  color: #d35400; /* Tamno narančasta boja za label */
}

.form-input {
  width: 70%; /* Manja širina polja */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px; /* Smanjen padding unutar input polja */
  margin-top: 4px; /* Smanjen razmak između labela i inputa */
  margin-bottom: 4px; /* Manji razmak ispod input polja */
  color: black;
}



/* Fokus na označenom unosu */
.form-input:focus {
  border-color: #ff8000; /* Promijeni boju obruba na tamno narančastu kad je unos fokusiran */
  background-color: rgba(255, 128, 0, 0.1); /* Lagana tamno narančasta pozadina kad je fokusiran */
  outline: none; /* Ukloni standardni obrub */
}

.add-client-button {
  width: 50%; /* Smanji širinu gumba */
  background-color: #e67e22;
  color: white;
  margin-top: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center; /* Tekst unutar gumba je centriran */
}

.add-client-button:hover {
  background-color: #d35400; /* Tamnija narančasta kod hovera */
}

.add-client-button:disabled {
  background-color: #cccccc; /* Zasivljena boja za onemogućen gumb */
}

h4 {
  text-align: center;
  color: #d35400; /* Tamno narančasta boja za naslov */
}

.selected-customer-table th, .selected-customer-table td {
  padding: 10px;
  border: 1px solid #ddd; /* Ograničenje između ćelija */
  text-align: center;
}

.selected-customer-table th {
  background-color: #f0f0f0; /* Svijetla pozadina za zaglavlje */
}

.selected-customer-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternativne boje za parne redove */
}

.selected-customer-table tr:hover {
  background-color: #e0e0e0; /* Promjena boje reda pri hoveru */
}

.search-input {
  width: 50%;
  padding: 2px;
  margin: 0 auto 0px auto; /* Centriraj i dodaj razmak na dnu */
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block; /* Dodaj display block kako bi margin auto funkcionirao */
  color: black;
}

.modal {
  width: 80%;
  height: 500px; /* Fiksna visina modala */
  max-height: 600px; /* Maksimalna visina modala */
  padding: 20px;
  overflow: auto; /* Omogućuje pomicanje sadržaja */
}

.tab-content {
  height: 100%;
  overflow-y: auto; /* Omogućuje scrollanje ako sadržaj prelazi visinu */
}

.search-bar-container {
  grid-column: span 2; /* Proširi se preko obje kolone */
  margin-bottom: 10px; /* Razmak ispod search bara */
  position: relative; /* Omogućava pozicioniranje apsolutnog elementa unutar kontejnera */
  display: flex; /* Postavi elemente u red */
  margin-top: 60px; /* Razmak iznad search bara */
  align-items: center; /* Vertikalno centriranje */
}

.search-input {
  width: 80%; /* Skraćena širina */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block;
  color: black;
  background-color: white; /* Bijela pozadina */
  box-sizing: border-box; /* Ukloni padding iz ukupne širine */
}

.add-service-button {
  width: 20%; /* Širina gumba */
  padding: 8px;
  margin-left: 10px; /* Razmak lijevo od search inputa */
  background-color: orange; /* Narančasta pozadina */
  color: white; /* Bijeli tekst */
  border: none; /* Bez granice */
  border-radius: 4px; /* Zaobljeni rubovi */
  cursor: pointer; /* Promjena pokazivača na ruku */
  box-sizing: border-box; /* Ukloni padding iz ukupne širine */
}

.add-service-button:hover {
  background-color: darkorange; /* Tamnija nijansa pri hoveru */
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Pozicioniraj odmah ispod search inputa */
  left: 0;
  right: 0;
  background-color: white; /* Bijela pozadina */
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px; /* Maksimalna visina padajućeg izbornika */
  overflow-y: auto; /* Omogućava skrolanje ako ima puno rezultata */
  z-index: 1000; /* Osiguraj da je iznad ostalog sadržaja */
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  color: black; /* Crni tekst */
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Promjena boje pozadine na hover */
}

.modal-content {

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow-y: auto; /* Omogućava pomicanje ako je sadržaj predugačak */
}

.save-service-button {
  background-color: orange; /* Narančasta pozadina */
  color: white; /* Bijeli tekst */
  border: none; /* Bez granice */
  padding: 10px 20px; /* Unutarnji razmak */
  border-radius: 4px; /* Zaobljeni rubovi */
  cursor: pointer; /* Promjena pokazivača na ruku */
  margin-top: 10px; /* Razmak iznad gumba */
}

.save-service-button:hover {
  background-color: darkorange; /* Tamnija nijansa pri hoveru */
}

@media (max-width: 768px) {
  /* Header ostaje fiksiran na vrhu */
  .profile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; /* Omogućava da se header automatski proširuje u visinu */
    background-color: #222;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }

  /* Kontejner za gumbe */
  .header-content {
    display: flex;
    flex-wrap: wrap; /* Omogućava prelamanje elemenata */
    justify-content: center;
    gap: 10px; /* Razmak između gumba */
    width: 100%;
    margin-top: 10px;
  }

  .header-item {
    flex: 1 1 100%; /* Svaki gumb zauzima cijelu širinu na manjim ekranima */
    text-align: center;
  }

  /* Gumbi unutar headera */
  .header-item button {
    width: 100%; /* Gumbi zauzimaju punu širinu kontejnera */
    padding: 8px 12px;
    font-size: 14px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .header-item button:hover {
    background-color: #555;
  }


  .placeholder {
    display: flex;
    justify-content: center; /* Centriranje sadržaja horizontalno */
    align-items: center; /* Centriranje sadržaja vertikalno */
    width: 99%;
    margin-left: 0px;
    padding: 0px;
  }

  /* Kontejner za usluge */
  .services-container {
    width: 100%;
    background-color: #333;
    padding: 0px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto; /* Omogućuje skrolanje unutar kontejnera */
    margin-top: 450px; /* Razmak između headera i sadržaja */
    max-height: 100vh; /* Ograničava visinu ekrana */
    position: sticky; /* Use sticky position */
    top: 100px; /* 100px from the top */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
  }

  /* Prikazivanje usluga u dva stupca unutar Services Grid */
  .services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Dva stupca */
    gap: 10px;
    width: 100%;
    max-width: 393px; /* Ograničava širinu grid-a na širinu ekrana u mobilnom prikazu */
    padding: 10px 0;
    background-color: #333;
  }

  /* Stil za service-box */
  .service-box {
    height: 60px;
    background-color: #444;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Stil za services-table */
/* Stil za services-table */
/* Stil za services-table */
.services-table {
  width: 100%; /* Tablica zauzima cijelu širinu ekrana */
  font-size: 14px;
  background-color: #f9f9f9;
  border-collapse: collapse;
  margin-top: 20px;
  max-height: 300px; /* Ograničava visinu tablice */
  overflow-y: auto; /* Omogućava vertikalno skrolanje unutar tablice */
  table-layout: fixed; /* Fiksna širina ćelija */
}


/* Header i Body Tablice */
.services-table thead, .services-table tbody {
  width: 100%;
  table-layout: fixed; /* Osiguravamo fiksnu širinu za cijelu tablicu */
}

/* Stil za ćelije tablice */
.services-table th, .services-table td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ddd;
  overflow-wrap: break-word; /* Omogućuje prelamanje teksta u ćeliji ako je predug */
  word-wrap: break-word; /* Isto kao overflow-wrap, za kompatibilnost */
  hyphens: auto; /* Omogućuje automatsko dodavanje prijeloma riječi */
  white-space: normal; /* Osigurava da tekst preljeće kad je potrebno */
}

/* Stil za gumbove za podešavanje količine i brisanje */
.adjust-icon, .delete-icon {
  font-size: 16px;
  cursor: pointer;
}

.delete-icon {
  color: red;
}

.footer-actions {
  display: flex;
  flex-direction: column; /* Postavljanje gumba jedan ispod drugoga */
  justify-content: flex-start; /* Gumbi se poravnavaju na početak */
  align-items: center; /* Centriranje gumba */
  margin-top: 10px;
  gap: 10px; /* Razmak između gumba */
  width: 100%; /* Kontejner zauzima cijelu širinu ekrana */
  max-width: 200px; /* Ograničavamo maksimalnu širinu kontejnera */
}

/* Gumbi unutar footer actions */
.footer-actions button {
  background-color: #444; /* Tamno sivkasta pozadina kao na desktopu */
  color: #fff; /* Bijeli tekst */
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Svi gumbi zauzimaju cijelu širinu kontejnera */
  max-width: 200px; /* Osigurava da svi gumbi imaju istu maksimalnu širinu */
}

.footer-actions button:last-child {
  margin-bottom: 10px; /* Dodaje razmak ispod posljednjeg gumba */
}

/* Hover efekat na gumbima */
.footer-actions button:hover {
  background-color: #555; /* Tamnija nijansa sivkaste boje na hover */
}

.footer-actions .create-invoice-button {
  background-color: orange; /* Ensure same color as desktop */
  color: white;
}

.footer-actions .create-invoice-button:hover {
  background-color: darkorange; /* Ensure same hover color as desktop */
}

.footer-actions .create-quotation-button {
  background-color: #007bff; /* Ensure same color as desktop */
  color: white;
}

.footer-actions .create-quotation-button:hover {
  background-color: #0056b3; /* Ensure same hover color as desktop */
}

.footer-actions .create-invoice-button:disabled,
.footer-actions .create-quotation-button:disabled {
  background-color: #cccccc; /* Siva boja pozadine */
  color: #666666; /* Siva boja teksta */
  cursor: not-allowed; /* Ikonica koja pokazuje da je gumb onemogućen */
}

.footer-actions .create-invoice-button:disabled:hover,
.footer-actions .create-quotation-button:disabled:hover {
  background-color: #130256; /* Održava istu sivu boju pri hoveru kad je onemogućen */
  color: #666666; /* Održava istu sivu boju teksta pri hoveru kad je onemogućen */
}

.selected-customer-table th, .selected-customer-table td {
  font-size: 12px; /* Manji font na mobilnim uređajima */
}

.clients-table th:nth-child(2),
.clients-table td:nth-child(2),
.clients-table th:nth-child(3),
.clients-table td:nth-child(3) {
  display: none;
}

/* Prikaz samo Naziva, Emaila i gumba za akciju */
.clients-table th:nth-child(1),
.clients-table td:nth-child(1),
.clients-table th:nth-child(2),
.clients-table td:nth-child(2),
.clients-table th:nth-child(3),
.clients-table td:nth-child(3) {
  width: 50%; /* Razmjerno širenje naziva i emaila */
}

.clients-table th:nth-child(3),
.clients-table td:nth-child(3) {
  width: 50%; /* Širina gumba za akciju */
}

  .add-customer-button {
    background-color: lightgray;
    color: black;
  }

  .add-customer-button:hover {
    background-color: #706f6f51;
  }

  .create-invoice-button {
    background-color: orange; /* Ensure same color as desktop */
    color: white;
  }

  .create-invoice-button:hover {
    background-color: darkorange; /* Ensure same hover color as desktop */
  }

  .create-quotation-button {
    background-color: #007bff; /* Ensure same color as desktop */
    color: white;
  }

  .create-quotation-button:hover {
    background-color: #0056b3; /* Ensure same hover color as desktop */
  }

  .create-quotation-button:disabled {
    background-color: #cccccc;
    color: #666666;
  }

  .create-quotation-button:disabled:hover {
    background-color: #130256;
    color: #666666;
  }

  .footer-actions .create-invoice-button:disabled,
  .footer-actions .create-quotation-button:disabled {
    background-color: #cccccc; /* Siva boja pozadine */
    color: #666666; /* Siva boja teksta */
    cursor: not-allowed; /* Ikonica koja pokazuje da je gumb onemogućen */
  }

  .footer-actions .create-invoice-button:disabled:hover,
  .footer-actions .create-quotation-button:disabled:hover {
    background-color: #130256; /* Održava istu sivu boju pri hoveru kad je onemogućen */
    color: #666666; /* Održava istu sivu boju teksta pri hoveru kad je onemogućen */
  }

}

@media (max-width: 400px) {
  .header-content {
    flex-direction: column; /* Prikaz svih gumba u zasebnim redovima */
  }

  .header-item {
    width: 100%; /* Svaki gumb u svom redu */
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .profile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #222;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }

  .header-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }

  .header-item {
    flex: 1 1 100%;
    text-align: center;
  }

  .header-item button {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .header-item button:hover {
    background-color: #555;
  }

  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 99%;
    margin-left: 0px;
    padding: 0px;
  }

  .services-container {
    width: 100%;
    background-color: #333;
    padding: 0px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-top: 140px;
    max-height: 100vh;
  }

  .services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
    max-width: 393px;
    padding: 10px 0;
    background-color: #333;
  }

  .service-box {
    height: 60px;
    background-color: #444;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
  }

  .services-table {
    width: 100%;
    font-size: 14px;
    background-color: #f9f9f9;
    border-collapse: collapse;
    margin-top: 20px;
    max-height: 300px;
    overflow-y: auto;
    table-layout: fixed;
  }

  .services-table thead, .services-table tbody {
    width: 100%;
    table-layout: fixed;
  }

  .services-table th, .services-table td {
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
  }

  .adjust-icon, .delete-icon {
    font-size: 16px;
    cursor: pointer;
  }

  .delete-icon {
    color: red;
  }

  .footer-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
    width: 100%;
    max-width: 200px;
  }

  .footer-actions button {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    max-width: 200px; /* Osigurava da svi gumbi imaju istu maksimalnu širinu */
  }

  .footer-actions button:last-child {
    margin-bottom: 10px;
  }

  .footer-actions button:hover {
    background-color: #555;
  }

  .footer-actions .create-invoice-button {
    background-color: orange; /* Ensure same color as desktop */
    color: white;
  }

  .footer-actions .create-invoice-button:hover {
    background-color: darkorange; /* Ensure same hover color as desktop */
  }

  .footer-actions .create-quotation-button {
    background-color: #007bff; /* Ensure same color as desktop */
    color: white;
  }

  .footer-actions .create-quotation-button:hover {
    background-color: #0056b3; /* Ensure same hover color as desktop */
  }

  .selected-customer-table th, .selected-customer-table td {
    font-size: 12px;
  }

  .clients-table th:nth-child(2),
  .clients-table td:nth-child(2),
  .clients-table th:nth-child(3),
  .clients-table td:nth-child(3) {
    display: none;
  }

  .clients-table th:nth-child(1),
  .clients-table td:nth-child(1),
  .clients-table th:nth-child(2),
  .clients-table td:nth-child(2),
  .clients-table th:nth-child(3),
  .clients-table td:nth-child(3) {
    width: 50%;
  }

  .clients-table th:nth-child(3),
  .clients-table td:nth-child(3) {
    width: 50%;
  }

  .create-invoice-button {
    background-color: orange; /* Ensure same color as desktop */
    color: white;
  }

  .create-invoice-button:hover {
    background-color: darkorange; /* Ensure same hover color as desktop */
  }

  .create-quotation-button {
    background-color: #007bff; /* Ensure same color as desktop */
    color: white;
  }

  .create-quotation-button:hover {
    background-color: #0056b3; /* Ensure same hover color as desktop */
  }

  .footer-actions .create-invoice-button:disabled,
  .footer-actions .create-quotation-button:disabled {
    background-color: #cccccc; /* Siva boja pozadine */
    color: #666666; /* Siva boja teksta */
    cursor: not-allowed; /* Ikonica koja pokazuje da je gumb onemogućen */
  }

  .footer-actions .create-invoice-button:disabled:hover,
  .footer-actions .create-quotation-button:disabled:hover {
    background-color: #130256; /* Održava istu sivu boju pri hoveru kad je onemogućen */
    color: #666666; /* Održava istu sivu boju teksta pri hoveru kad je onemogućen */
  }

}

@media (max-width: 400px) and (orientation: landscape) {
  .header-content {
    flex-direction: column;
  }

  .header-item {
    width: 100%;
  }
}


