.action {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.table-container2 {
  position: sticky;
  top: 60px; /* Adjust this value based on the actual height of the header */
  flex-grow: 1;
  max-height: calc(100vh - 60px); /* Adjust this value based on the actual height of the header */
  overflow-x: auto; /* Omogućuje horizontalno skrolanje */
  margin: 0 auto; /* Adjusted margin-top to move the table below the header */
  padding: 0 10px; /* Unutarnje margine */
  display: flex; /* Omogućava punu širinu */
  flex-direction: column;
  padding-top: 60px; /* Adjust this value based on the actual height of the header */
  overflow-y: auto; /* Omogućuje vertikalno skrolanje */
}

@media (max-width: 1025px) {
  .table-container2 {
    padding-top: 100px; /* Adjust this value based on the actual height of the header when it wraps to two lines */
  }
}

.quotation-table {
  width: 100%; /* Pun širina tablice */
  min-width: 600px; /* Minimalna širina tablice za osiguranje skrolanja */
  border-collapse: collapse; /* Eliminira razmake između ćelija */
  margin-top: 0px; /* Razmak iznad tablice */
  color: #ffffff; /* Boja teksta */
  table-layout: fixed; /* Fiksna širina stupaca */
}

.quotation-table th, .quotation-table td {
  padding: 10px;
  border: 1px solid #444;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quotation-table th {
  background-color: #333333; /* Dark gray for header */
  color: #ffffff; /* White text */
}

.quotation-table tr:nth-child(even) {
  background-color: #222222; /* Darker gray for even rows */
}

.quotation-table tr:nth-child(odd) {
  background-color: #333333; /* Slightly lighter gray for odd rows */
}

.quotation-table tr:hover {
  background-color: #444444; /* Medium gray on hover */
}

.preview-button {
  background-color: #FFA500; /* Narančasta boja */
  color: #ffffff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.preview-button:hover {
  background-color: #e69500; /* Tamnija nijansa narančaste na hover */
}

.create-invoice-button2 {
  background-color: #28a745; /* Zeleno */
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.create-invoice-button2:hover {
  background-color: #218838; /* Tamnija nijansa zelene na hover */
}

.paid {
  color: green;
}

.unpaid {
  color: red;
}

@media (max-width: 768px) {
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
  }

  .table-container2 {
    width: 100vw;
    margin: 0 auto;
    padding: 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 190px; /* Adjust this value based on the actual height of the header */
    overflow-y: auto; /* Omogućuje vertikalno skrolanje */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .quotation-table {
    width: max-content;
    min-width: 800px;
    margin: 20px 0 0 0;
    border-collapse: collapse;
    font-size: 12px; /* Manja veličina fonta za mobilni prikaz */
  }

  .quotation-table th,
  .quotation-table td {
    white-space: nowrap;
    padding: 6px;
    min-width: 100px;
    height: auto;
    vertical-align: middle;
  }

  /* Stil za ćelije koje sadrže gumbe */
  .quotation-table td.action {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Stil za gumbe */
  .quotation-table button {
    margin: 2px;
    padding: 5px 10px;
    height: 30px;
    min-width: 60px;
  }

  .preview-button {
    height: 30px;
    margin: 2px;
  }
}