.names-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.name-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #FFA500; /* Narančasta boja */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;
}

.name-circle:hover {
  transform: scale(1.1);
  background-color: #FF8C00; /* Tamnija narančasta prilikom hovera */
}

/* Media query za mobilni prikaz */
@media (max-width: 768px) {
  .names-container {
      gap: 10px; /* Smanjen razmak među elementima */
      padding: 10px; /* Smanjena padding za mobilni prikaz */
  }

  .name-circle {
      width: 80px; /* Manji krugovi na mobilnom */
      height: 80px;
      font-size: 14px; /* Smanjen font unutar krugova */
      background-color: #FFA500; /* Narančasta boja za konzistentnost */
  }

  .name-circle:hover {
      background-color: #FF8C00; /* Tamnija narančasta pri hoveru na mobilnom */
  }
}
